
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { handleAnonymizeName } from '@/composables/DataAnonymization';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";


interface ICategoryData {
  id: number,
  name: string,
}

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: ICategoryData
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: ICategoryData): boolean {
  for (let key in obj) {
    if (obj[key] === "") {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "CompanyPut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const route = useRoute();
    const loaderStore = useLoaderStore();

    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | ICategoryData
    }>({
      isLoading: false,
      noError: false,
      data: null
    })

    onMounted(async () => {
      loaderStore.open()
      try {
        const result: ICompanyResponse = await axios.get(`/api/getCategoryView/${id}`)
        const { data } = result
          return queryObject.value.data = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    })

    const { id } = route.params;
    const { showTimeAlert } = useAlert()
    
    /** Warning of unfilled fields */
    const isRequiredField = ref(false)

    async function onSubmitForm() {
      isRequiredField.value = false
      const { data } = queryObject.value
      if(!data) {
        return router.push("/404")
      }
      if(checkEmptyFields(data)) {
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const { data } = queryObject.value
        if(data) {
          const { id, name } = data
          const result: ICompanyResponse = await axios.put(`/api/putCategory`, { id, name })
            showTimeAlert("Atualizado com sucesso!")
          return router.push("/categorias")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    return {
      queryObject,
      isRequiredField,
      onSubmitForm,
      handleAnonymizeName
    }
  }
})
